<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 card-company-table">
        <!-- Search -->
        <div class="m-2">
          <b-row>
            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" v-b-modal.modal-centered>
                  <span class="text-nowrap">Add New Promotion</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Services Table -->
        <Table
          :items="promotions"
          :fields="fields"
          @editFunc="editPromotionData"
          @delFunc="delPromotionData"
        />
      </b-card>
    </b-card>

    <!--Model-->
    <div>
      <b-modal
        v-model="myModal"
        id="modal-centered"
        centered
        size="lg"
        header-class="text-uppercase"
        scrollable
        :title="isEdit ? '' : ''"
        hide-footer
      >
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div
            class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            "
          >
            <h5 class="mb-0" v-if="!isEdit">Add New Promotion</h5>
            <h5 class="mb-0" v-if="isEdit">Edit Promotion</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>

        <form ref="form" @submit.prevent="handleSubmit">
          <b-form-group>
            <!-- Title -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="title" class="font-weight-bolder">Name :</label>
                  <b-form-input
                    v-model="postForm.name"
                    id="title"
                    placeholder="Enter Promotion Title"
                    required
                  >
                  </b-form-input>
                </div>
              </b-col>
            </b-row>

            <!-- status & parent_id -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="url" class="font-weight-bolder">Url :</label>
                  <b-form-input
                    v-model="postForm.url"
                    id="url"
                    placeholder="ex. https://ooak.jp"
                    required
                  >
                  </b-form-input>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder"
                    >Publish :</label
                  >
                  <b-form-checkbox
                    v-model="checked"
                    @change="toggle(checked)"
                    name="check-button"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>

            <!-- Photo -->
            <div>
              <MyPhotoSingleUpload
                @getPhotoId="uploadPhoto"
                :isEdit="isEdit"
                :editPhotoObj="photoObj"
              />
            </div>
          </b-form-group>

          <template v-if="!isEdit">
            {{ allUploaded() }}
          </template>

          <div class="w-100 text-center" v-if="!isEdit">
            <b-button variant="primary" type="submit" class="col-6" :disabled="!isAllUploaded" >
              <b-spinner
                variant="white"
                v-if="spinner === true"
                label="Text Centered"
              ></b-spinner>
              <span v-else>Submit</span>
            </b-button>
          </div>

          <div class="w-100 text-center" v-if="isEdit">
            <b-button
              type="button"
              variant="primary"
              @click="updatePromotionData(postForm.id)"
              class="col-6"
            >
              <b-spinner
                variant="white"
                v-if="spinner === true"
                label="Text Centered"
              ></b-spinner>
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </b-modal>
    </div>
    <!--Model-->
  </div>
</template>
  
<script>
import store from "@/store";
import Table from "../components/Table.vue";
import MyPhotoSingleUpload from "../components/MyPhotoSingleUpload.vue";
import { ref, reactive } from "@vue/composition-api";
import {
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    Table,
    MyPhotoSingleUpload,
  },
  emits: ["input"],
  setup(props, { emit }) {
    const fields = [
      { key: "id", label: "#" },
      { key: "name", label: "name" },
      { key: "promo_url", label: "url" },
      { key: "publish", label: "publish" },
      { key: "photo_url_promo", label: "photo" },
      { key: "actions", label: "actions" },
    ];
    const spinner = ref(false);
    const myModal = ref(false);
    const isEdit = ref(false);
    const promotions = ref([]);
    const checked = ref(true);
    const isAllUploaded = ref(false);
    const photoObj = ref({});
    const postForm = ref({
      id: "",
      name: "",
      url: "",
      photo_id: "",
      publish: "yes",
    });

    //Datas Fetching
    const fetchPromotions = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/fetchDatas", "promotions")
        .then((response) => {
          promotions.value = response.data.data;
          spinner.value = false;
        });
    };
    fetchPromotions();

    //Functions
    // Photo Upload
    const uploadPhoto = (photoId) => {
      postForm.value.photo_id = photoId;
    };
    const toggle = (c) => {
      c ? (postForm.value.publish = "yes") : (postForm.value.publish = "no");
    };
    // Clear Func
    const clearData = () => {
      postForm.value.id = "";
      postForm.value.name = "";
      postForm.value.url = "";
      postForm.value.photo_id = "";
      postForm.value.publish = "yes";
    };

    //Data Posting
    const handleSubmit = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/postDatas", {
          path: "promotions",
          payload: postForm.value,
        })
        .then((response) => {
          spinner.value = false;
          clearData();
          fetchPromotions();
          myModal.value = false;
          isEdit.value = false;
        });
    };

    // Data Editing
    const editPromotionData = (item) => {
      myModal.value = true;
      isEdit.value = true;

      postForm.value.id = item.id;
      postForm.value.name = item.name;
      postForm.value.photo_id = item.photo_id;
      photoObj.value = item.media;
      postForm.value.publish = item.publish;
      postForm.value.url = item.url;
    };
    const updatePromotionData = (id) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/updateData", {
          path: "promotions",
          payload: postForm.value,
        })
        .then((response) => {
          fetchPromotions();
          spinner.value = false;
          myModal.value = false;
          isEdit.value = false;
        });
    };

    // Data Deleting
    const delPromotionData = (id) => {
      spinner.value = true;
      console.log(id);
      store
        .dispatch("app-mypages/delData", { path: "promotions", id })
        .then((response) => {
          fetchPromotions();
          spinner.value = false;
        });
    };

    // Close Function
    const close = () => {
      myModal.value = false;
      isEdit.value = false;
      clearData();
    };

    // All Uploaded
    const allUploaded = () => {
      if (postForm.value.name &&
        postForm.value.url &&
        postForm.value.photo_id.length !== 0) {
        isAllUploaded.value = true;
      }
    }


    return {
      fields,
      spinner,
      myModal,
      isEdit,
      promotions,
      handleSubmit,
      photoObj,
      uploadPhoto,
      toggle,
      postForm,
      checked,
      editPromotionData,
      updatePromotionData,
      delPromotionData,
      close,
      allUploaded,
      isAllUploaded,
    };
  },
};
</script>